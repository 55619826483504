import SceneManager from "./sceneManager";
import CaseManager from "./case/caseManager";
import KeyManager from "./key/keyManager";
import * as webfont from "webfontloader";

const SCREEN_SCALE = 50;
 
export default (element) => {
  //ensure fonts loaded for canvas textures
  webfont.load({
    custom: {
      families: ["legends", "Varela Round"],
    },
    active: function () {
      //MAIN THREE JS SETUP
      //-------------------------------------
      window.ThreeApp = new SceneManager({
        scale: SCREEN_SCALE,
        el: element,
      });

      window.Keys = new KeyManager({
        scene: window.ThreeApp.scene,
      });

      window.Case = new CaseManager({
        scene: window.ThreeApp.scene,
      });

      //start render loop
      window.ThreeApp.add(window.Keys);
      window.ThreeApp.tick();

    
    },
  });
};
