import React from "react";
import ScreenShot from "./ScreenShot";
import { ReactComponent as GithubIcon } from "../../assets/icons/icon_github.svg";
import styles from "./action.module.scss";
import Purchase from "./Purchase";

export default function QuickActions() {
  return (
    <div className={styles.actionBar}>
      {/* <Permalink /> */}
      <Purchase />
      <ScreenShot />
      {/* <a
        className={styles.action}
        href="https://github.com/crsnbrt/keysim"
        aria-label="keyboard simulator on github"
        target="_blank"
      >
        <GithubIcon />
      </a> */}
    </div>
  );
}
