import React, { useState } from "react"; 
import account from "../../api/account"; 
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import * as userInfo from "../../util/userinfo"
export default function Login() {
  const [tabIndex, setTabIndex] = useState(1);
  let [resName,setResName] = useState('');
  let [loginName=1,setLoginName] = useState('');
  let [email=0,setEmial] = useState('');
  let [resPwd=0,setResPwd] = useState('');
  let [loginPwd=0,setLoginPwd] = useState('');
  const registerFunc =(e)=>{
    account.register({
      "username": resName,
      "email": email,
      "password": resPwd
    }).then((res)=>{
      setTabIndex(1)
      setResName('')
      setEmial('')
      setResPwd('')
    })
  }
  
  const loginFunc =(e)=>{
    account.login({
      "identifier": loginName,
      "password": loginPwd
    }).then((res)=>{
        userInfo.saveInfo({
           id:res.user.id,
           username:res.user.username,
           token:res.jwt,
           token_expire: new Date().setDate(new Date().getDate() + 6),
           email:res.user.email
        })
        document.dispatchEvent(new CustomEvent("user_logined", {
          detail:res.user.username,
        }));
        setLoginName('')
        setLoginPwd('')
        userInfo.closeLoginWin()
    })
  }
  

  return (
    <div className="bg-black absolute top-0 left-0 w-screen h-screen z-10 bg-opacity-40 flex justify-center items-center  ">
        <div className=" w-128 bg-black bg-opacity-90 rounded-md py-4">
          {/* <span id={styles.login_close} onClick={e=>{
                    userInfo.closeLoginWin()
                  }}>
                    <i className="fa fa-close-o" aria-hidden="true"></i>
                  </span> */}
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab tabIndex="0">Register</Tab>
            <Tab tabIndex="1">Login</Tab>
          </TabList>
          <TabPanel>
              <div className="py-4 px-8">
                {/* <h1>Account</h1> */}
                <div >
                  <div className="mb-3">
                    <span className="mb-2 text-sm">Username</span>
                    <input className="text-sm" type="text" value={resName} onChange={e=>{setResName(e.target.value)}} placeholder="Username"/>
                  </div>
                  <div className="mb-3">
                    <span className="mb-2 text-sm">Email</span>
                    <input className="text-sm"  type="text" value={email} onChange={e=>{setEmial(e.target.value)}}  placeholder="Email"/>
                  </div>
                  <div className="mb-2">
                    <span className="mb-2 text-sm">Passowrd</span>
                    <input className="text-sm"  type="password" value={resPwd} onChange={e=>{setResPwd(e.target.value)}}  placeholder="Password"/>
                  </div>
                </div>
                <button className="border-primary border-solid border-2 text-primary my-2 rounded w-full px-2 py-3 text-sm hover:bg-primary hover:text-white transition-all duration-300 mt-4" onClick={registerFunc}>Submit</button> 
              </div>
          </TabPanel>
          <TabPanel>
            <div className="py-4 px-8">
                {/* <h1>Account</h1> */}
                <div>
                  <div className="mb-3">
                    <span className="mb-2 text-sm">Username</span>
                    <input className="text-sm"  type="text"  placeholder="Username" value={loginName} onChange={e=>{setLoginName(e.target.value)}}/>
                  </div>
                  <div className="mb-3">
                    <span className="mb-2 text-sm">Passowrd</span>
                    <input className="text-sm"  type="password" placeholder="Password" value={loginPwd} onChange={e=>{setLoginPwd(e.target.value)}}/>
                  </div>
                </div>
                <button className="border-primary border-solid border-2 text-primary my-2 rounded w-full px-2 py-3  text-sm hover:bg-primary hover:text-white transition-all duration-300 "  disabled={!loginName || !loginPwd} onClick={loginFunc}>Submit</button>
              </div>
          </TabPanel>
        </Tabs>
    </div>
  </div>
  );
}
