import React from "react";
import ColorwayList from "../colorway/ColorwayList";
import BoardOptions from "./BoardOptions";

import { useSelector, useDispatch } from "react-redux";
import * as settingsActions from "../../store/slices/settings";

import PictureOptions from "./PictureOptions";

import About from "./About";
export default function OptionsPane(props) {


  const diyType = useSelector(settingsActions.selectDiyType);


 window.design ={}
  return (
    <>
      <BoardOptions />
      {/* {diyType == 1 && <PictureOptions /> } */}
      <ColorwayList   setTab={props.setTab}/>
      <About />
    </>
  );
}
