  export const checkLogin = ()=> {
    try {
      const json  = localStorage.getItem("user")
      if(json !== null){
        const user = JSON.parse(json);
        if (!user.token_expire) {
          localStorage.removeItem("user");
        } else if(new Date().getTime() > user.token_expire) {
          localStorage.removeItem("user");
        }
      } else {
        localStorage.removeItem("user");
      }
    } catch (error) {
      
    }
  }



  export const saveInfo = (info) => {
    try {
      const json = JSON.stringify(info);
      localStorage.setItem("user", json);
    } catch (err) {
      console.log(err);
    }
  };
 

  export const getUserToken =()=>{
      try{
        const json  = localStorage.getItem("user")
        if(json === null){
          return undefined;
        }
        return JSON.parse(json).token
      }catch(err){
        console.log(err);
      }
  }

  export const getUserName =()=>{
    try{
      const json  = localStorage.getItem("user")
      if(json === null){
        return undefined;
      }
      return JSON.parse(json).username
    }catch(err){
      console.log(err);
    }
}
export const getUserId =()=>{
  try{
    const json  = localStorage.getItem("user")
    if(json === null){
      return undefined;
    }
    return JSON.parse(json).id
  }catch(err){
    console.log(err);
  }
}

  export const getUserInfo=()=>{
    try{
        const json  = localStorage.getItem("user")
        if(json === null){
          return undefined;
        }
        return JSON.parse(json) 
      }catch(err){
        console.log(err);
      }
  }
 

  export const showLoginWin =()=>{
      let event = new CustomEvent("show_loginWin", {
        detail: true,
      });
      document.dispatchEvent(event);
  }

  export const closeLoginWin =()=>{
    let event = new CustomEvent("show_loginWin", {
      detail: false,
    });
    document.dispatchEvent(event);
}
  


export const userLogout =()=>{
  try{
    const json  = localStorage.getItem("user")
    if(json === null){
      return;
    }
    document.dispatchEvent(new CustomEvent("user_logout"));
    localStorage.removeItem("user")
  }catch(err){
    console.log(err);
  }
}


