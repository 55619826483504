// import axios from "axios";
import {get,post} from  "../util/request";

export default {
  login(data) {
    return post('/auth/local', data)
  },
  register(data) {
    return post('/auth/local/register', data)
  },
}