

import {get,post} from  "../util/request";

export default {
  post(data) {
    return post('/upload', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
      }}
    )
  },
}
  