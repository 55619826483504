import React, { useState } from "react";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, UserIcon, LogoutIcon } from '@heroicons/react/solid'

import * as userInfo from "../../util/userinfo"


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function LogginMap(props) {

  userInfo.checkLogin();

  let [name, setName] = useState(userInfo.getUserName());
  document.addEventListener("user_logined", (e) => {
    setName(e.detail)
  });
  document.addEventListener("user_logout", (e) => {
    setName(null)
  });
  
  return (
    <>
      <div className="absolute z-10 top-6 right-10">
        {name? <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button>
              <div className="text-white bg-black text-base py-3 rounded-md px-4 hover:text-primary cursor-pointer transition-all duration-300">
                  <div className="inline-flex justify-center">
                    <UserIcon className="mr-2 h-5 w-5"></UserIcon>
                    <span>{name}</span>
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                  </div>
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-black ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
              <Menu.Item 
                  onClick={(e)=>{
                    setName('')
                    userInfo.userLogout()
                  }}>
                {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-900 text-white' : 'text-white',
                    ' px-4 py-2 text-sm inline-flex justify-center w-full'
                  )}
                >
                  <LogoutIcon className="mr-2 h-5 w-5"></LogoutIcon>
                  <span>Sign out</span>
                </a>
              )}
              </Menu.Item>
            </Menu.Items> 
          </Transition>
        </Menu>
        : <div className="inline-flex justify-center text-white bg-black text-base py-3 rounded-md px-4  cursor-pointer hover:bg-light1"  onClick={e => {userInfo.showLoginWin() }}>
        <UserIcon className="mr-2 h-5 w-5"></UserIcon>
        <span className="text-sm font-medium">SIGN IN</span>
      </div> }
      </div>
    </>
  );
}

