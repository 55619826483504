import React, { useState } from "react"; 
import CollapsibleSection from "../containers/CollapsibleSection";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as CheckIcon } from "../../assets/icons/icon_check.svg";
import Button from "../elements/Button";
import Image from "../elements/Image";
import upload from "../../api/upload.js";
import styles from "./PictureOptions.module.scss";

import userDesign from "../../util/userDesign";
import * as settingsActions from "../../store/slices/settings";



export default function PictureOptions() {
  let imgId;
  let [key,setkey] = useState('');
  let [zoom,setzoom] = useState(1);
  let [top,settop] = useState(0);
  let [left,setleft] = useState(0);
  let [isUploadImg,setIsUploadImg] = useState(false);

  let [btnTitle=0,setbtnTitle] = useState('cancel');

  const dispatch = useDispatch();

  const isUploadPng = useSelector(settingsActions.selectIsUploadPng);

  const handleChangeUploadPng = (e) => {
    dispatch(settingsActions.isUploadPng(!isUploadPng));
  }
  

  const uploadImg = (file)=>{
      let param = new FormData(); //创建form对象
      param.append('files',file);//通过append向form对象添加数据 
      upload.post(param).then(res=>{ 
        userDesign.imgId = res[0].id
        setIsUploadImg(true);
      })
  } 

 const updateMaterialPic = (e)=>{  
  if(btnTitle === 'Start painting'){
    const temp ={ 
      key:'',
      zoom:parseFloat(zoom?zoom:1),
      top:parseInt(top?top:0),
      left:parseInt(left?left:0),
    }
    document.dispatchEvent( new CustomEvent("paint_cap_img", {
      detail:{
         isPaint:true,
         data:temp
      },
    }));
    setbtnTitle('Stop painting')
  }
  else{
    document.dispatchEvent(new CustomEvent("paint_cap_img", {
      detail:{

      } ,
    }));
    setbtnTitle('Start painting')
  }
 }
  return (
    <>
        <CollapsibleSection title="Image upload" open={true}>

        <Image Id="CustTexture-Img" handler={uploadImg} fileChangeEvent={f=>{
           if(f){
            setbtnTitle('Start painting')
           }
           else{
            setbtnTitle('Stop painting')
           }
        }}/>
        {/*<ul>
        {/* <li>
            Key:
            <input type="text"   onChange={e=>{
                  setkey(key ="KC_" + e.target.value ) 
            }} id="CustTexture-Key"/>
         </li>
         <li>Left:
             <input type="text" value={left}    onChange={e=>{ setleft(e.target.value) }} id="CustTexture-Left"/>
             Top:
             <input type="text"  value={top} onChange={e=>{ settop(e.target.value) }}  id="CustTexture-Top"/>
         </li>
         <li>
            Zoom:
            <input type="text" value={zoom} onChange={e=>{ setzoom(e.target.value) }}   id="'CustTexture-Zoom"/>
         </li>
        
         <li>
         
         </li>
        </ul> */}
        {isUploadImg? <Button title={btnTitle}  handler={(e)=>{
              updateMaterialPic(e)
         }} /> : ''}
       

       
        <div className="mt-4">
          <h2 className="text-base mb-2">Tips: Please refer to the size and upload {isUploadPng ? "PNG": "SVG"} file! </h2>
          <ul className="leading-6 text-sm opacity-80">
            <li>1u:  W40.5 x H48</li>
            <li>1.25u: W56.5 x H48</li>
            <li>1.5u:  W72.5 x H48</li>
            <li>1.75u:  W88.5 x H48</li>
            <li>2u:  W104.5 x H48</li>
            <li>2.25u:  W120.5 x H48</li>
            <li>2.75u:  W152.5 x H48</li>
            <li>6.25u:  W376.5 x H48</li>
            <li>Enter key:  W72.5 x H111.5</li>
          </ul>
        </div>

        <div onClick={handleChangeUploadPng} className={styles.isUploadPng + " " + (isUploadPng ? styles.isUploadPngActive : ' ')  + " transition-all duration-300  my-4 cursor-pointer bg-light1 p-4 rounded font-medium flex"}>
            <div>
              <h2 className="text-base">I need to upload PNG file {isUploadPng}</h2>
              <p className="text-sm opacity-80">In order to ensure the quality, we need to convert it into SVG manually, which will charge us <span className="text-red-500">$5</span> more.</p>
            </div>
        </div>
        
     
      </CollapsibleSection>
    </>
  );
}

 