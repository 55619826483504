import { get_qs_values } from "./qs";
import { loadState } from "./localStorage";
import { subOptions } from "../config/legends/subs/subs";
import COLORWAYS from "../config/colorways/colorways";
import settings from "../config/settings_user_default.json";
import userDesign from "../util/userDesign";
const starting_colorway_options = [
  "cafe",
  "mecha",
  "lunar",
  "jamon",
  "bento",
  "olivia",
  "striker",
  "bushido",
  "oblivion",
  "nautilus",
  "vilebloom",
  "handarbeit",
  "hammerhead",
  "modern_dolch",
  "blue_samurai",
  "red_samurai",
];

const starting_layout_options = ["60iso", "65"];


userDesign.init()


let randomItem = (arr) => {
  const index = Math.floor(Math.random() * arr.length)
  return arr[index];
};
// const getInitialState = () => {
//   let qs = get_qs_values();
//   let saved_colorways = loadState();
//   // let initial = settings;

//   let initial ={
//     "settings": {
//       "mute": true,
//       "debug": false,
//       "testing": false,
//       "mode": "sidebar",
//       "sceneAutoColor": true,
//       "sceneColor": "#cccccc",
//       "glowColor": "#ffffff",
//       "highContrast": false,
//       "paintWithKeys": false
//     },
//     "case": {
//       "autoColor": true,
//       "primaryColor": "#eeeeee",
//       "colorSecondary": "#eeeeee",
//       "style": "CASE_2",
//       "bezel": 1,
//       "layout": "60iso",
//       "profile": "high",
//       "material": "brushed"
//     },
//     "keys": {
//       "visible": true,
//       "profile": "mx",
//       "legendPrimaryStyle": "cherry",
//       "legendSecondaryStyle": "",
//       "activeBackground": "#51cf59",
//       "activeColor": "#ffffff"
//     },
//     "switches": {
//       "stemColor": "red",
//       "bodyColor": "blue",
//       "soundProfile": "default"
//     },
//     "colorways": {
//       "editing": false,
//       "activeSwatch": "accent",
//       "active": "modern_dolch",
//       "custom": []
//     }
//   }
//   if (saved_colorways) {
//     initial.colorways.custom = saved_colorways.settings;
//   }

//   //set random initial values
//   if (!qs) {
//     initial.colorways.active = randomItem(starting_colorway_options);
//     initial.case.layout = randomItem(starting_layout_options);
//     initial.keys.legendSecondaryStyle = randomItem([
//       randomItem(subOptions),
//       "",
//     ]);
//   }

//   if (saved_colorways && saved_colorways.active) {
//     initial.colorways.active = saved_colorways.active;
//   }

//   if (qs && qs["debug"]) {
//     initial.settings.debug = true;
//   }
//   //set initial values if in query string
//   if (qs && qs["size"]) {
//     initial.case.layout = qs["size"];
//   }
//   if (qs && qs["colorway"]) {
//     if (typeof qs["colorway"] === "object") {
//       if (!initial.colorways.custom.find((x) => x.id === qs["colorway"].id)) {
//         initial.colorways.custom.push(qs["colorway"]);
//       }
//       initial.colorways.active = qs["colorway"].id;
//     } else {
//       initial.colorways.active = qs["colorway"];
//     }
//   }
//   if (qs && qs["legend"]) {
//     initial.keys.legendPrimaryStyle = qs["legend"];
//   }
//   if (qs && qs["sub"]) {
//     initial.keys.legendSecondaryStyle = qs["sub"];
//   }
//   if (qs && qs["cc"]) {
//     initial.case.primaryColor = `#${qs["cc"]}`;
//     initial.case.autoColor = false;
//   }
//   if (qs && qs["cf"]) {
//     initial.case.material = qs["cf"];
//   }

//   let accent = "";
//   if (qs && typeof qs["colorway"] === "object") {
//     accent = qs["colorway"].swatches.accent.background;
//   } else {
//     accent =
//       COLORWAYS[initial?.colorways?.active]?.swatches?.accent?.background;
//   }
//   initial.settings.sceneColor = accent;
//   return initial;
// };


const getInitialState = () => {
  let qs = get_qs_values();
  let saved_colorways = loadState();
  // let initial = settings;

  let initial ={
    "settings": {
      "isUploadPng": false,
      "diyType": 0,
      "mute": true,
      "debug": false,
      "testing": false,
      "mode": "sidebar",
      "sceneAutoColor": true,
      "sceneColor": "#cccccc",
      "glowColor": "#ffffff",
      "highContrast": false,
      "paintWithKeys": false
    },
    "case": {
      "autoColor": true,
      "primaryColor": "#eeeeee",
      "colorSecondary": "#eeeeee",
      "style": "CASE_2",
      "bezel": 1,
      "layout": "60iso",
      "profile": "high",
      "material": "brushed"
    },
    "keys": {
      "visible": true,
      "profile": "mx",
      "legendPrimaryStyle": "cherry",
      "legendSecondaryStyle": "",
      "activeBackground": "#51cf59",
      "activeColor": "#ffffff"
    },
    "switches": {
      "stemColor": "red",
      "bodyColor": "blue",
      "soundProfile": "default"
    },
    "colorways": {
      "editing": false,
      "activeSwatch": "accent",
      "active": "modern_dolch",
      "custom": []
    }
  }
  if (saved_colorways) {
    initial.colorways.custom = saved_colorways.settings;
  }

  //set random initial values
  
   if(userDesign.design.has){
      initial.case.layout = userDesign.design.layout
      initial.case.style = userDesign.design.style
      initial.case.material = userDesign.design.material
      initial.case.primaryColor =userDesign.design.primaryColor



      //initial.settings.sceneColor = userDesign.design.sceneColor


      initial.keys.legendSecondaryStyle = userDesign.design.subLegends
      initial.keys.legendPrimaryStyle = userDesign.design.legendStyle
      //initial.colorways.active= "lunar"
      
   }
   else  if (!qs) {
    initial.colorways.active = randomItem(starting_colorway_options);
    initial.case.layout = randomItem(starting_layout_options);
    initial.keys.legendSecondaryStyle = randomItem([
      randomItem(subOptions),
      "",
    ]);
  }
  // console.log(1);
  // if (saved_colorways && saved_colorways.active) {
  //   initial.colorways.active = saved_colorways.active;
  // }

  if (qs && qs["debug"]) {
    initial.settings.debug = true;
  }
  //set initial values if in query string
  if (qs && qs["size"]) {
    initial.case.layout = qs["size"];
  }
  if (qs && qs["colorway"]) {
    if (typeof qs["colorway"] === "object") {
      if (!initial.colorways.custom.find((x) => x.id === qs["colorway"].id)) {
        initial.colorways.custom.push(qs["colorway"]);
      }
      initial.colorways.active = qs["colorway"].id;
    } else {
      initial.colorways.active = qs["colorway"];
    }
  }
  if (qs && qs["legend"]) {
    initial.keys.legendPrimaryStyle = qs["legend"];
  }
  if (qs && qs["sub"]) {
    initial.keys.legendSecondaryStyle = qs["sub"];
  }
  if (qs && qs["cc"]) {
    initial.case.primaryColor = `#${qs["cc"]}`;
    initial.case.autoColor = false;
  }
  if (qs && qs["cf"]) {
    initial.case.material = qs["cf"];
  }

  let accent = "";
  if (qs && typeof qs["colorway"] === "object") {
    accent = qs["colorway"].swatches.accent.background;
  } else {
    accent =
      COLORWAYS[initial?.colorways?.active]?.swatches?.accent?.background;
  }
  initial.settings.sceneColor = accent;
  return initial;
};



export const initial_settings = getInitialState();
