import React, { useState, useRef } from "react";
import { getPermalink } from "../../store/qs";
import { ReactComponent as PurchaseIcon } from "../../assets/icons/icon_purchase.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/icon_success.svg";
import styles from "./action.module.scss";
import { useSelector } from "react-redux";
import * as caseActions from "../../store/slices/case";
import * as keyActions from "../../store/slices/keys";
import * as settingsActions from "../../store/slices/settings";

import {
    selectColorway, 
    selectColorways, 
  } from "../../store/slices/colorways";
import ColorUtil from "../../util/color";
import Util from "../../util/math";
import commonUtil from "../../util/commonUtil";

import upload from "../../api/upload.js";
import order from "../../api/order.js";
import design from "../../api/design";
import userDesignUtil from "../../util/userDesign";
import * as userInfo from "../../util/userinfo"


import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function PurchaseButton() {
  
  const layout =  useSelector(caseActions.selectLayout);
  const legendPrimaryStyle = useSelector(keyActions.selectLegendPrimaryStyle);
  const legendSecondaryStyle = useSelector( keyActions.selectLegendSecondaryStyle);
  const primaryColor = useSelector(caseActions.selectPrimaryColor);
  const style = useSelector(caseActions.selectStyle);
  const material = useSelector(caseActions.selectMaterial);
  const isUploadPng = useSelector(settingsActions.selectIsUploadPng);
  const sceneColor = useSelector(settingsActions.selectSceneColor);
  const diyType = useSelector(settingsActions.selectDiyType);
  const colorwayId = useSelector(selectColorway);
  const colorwayList = useSelector(selectColorways); 
  let colorway = colorwayList.find((x) => x.id === colorwayId);
  if (!colorway) {
    colorway = JSON.parse(JSON.stringify(ColorUtil.getColorway(colorwayId)));
    colorway.label += " modified";
    colorway.id = `cw_${Util.randString()}`; 
  }

  
  const newWin = (url, id) => {
    var a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    a.setAttribute('id', id);
    // 防止反复添加
    if(!document.getElementById(id)) {                     
        document.body.appendChild(a);
    }
    a.click();
  }

  const node = useRef(null);
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("Order this design");
  const [isShowModal, setShowModal] =  useState(false);
  const [checkoutLink, setCheckoutLink] =  useState('');
  const [isUploading, setIsUploading] =  useState(false);

  const buy = async (e) => {
    try{
      const user = userInfo.getUserInfo()
      if(user && user.username){
        if (isUploading) {
          return;
        }
        setIsUploading(false);
        const imgData = await window.ThreeApp.printImage()
        if(imgData){
          const file = commonUtil.dataURLtoFile(imgData)
          let param = new FormData(); //创建form对象
          param.append('files',file);//通过append向form对象添加数据
          const uploadRes = await upload.post(param);
          const temp =  {
            layout,
            legendPrimaryStyle,
            legendSecondaryStyle,
            primaryColor,
            style,
            material,
            sceneColor,
            colorway,
            custImgs:userDesignUtil.getImgList(),
          }  
          let designData = userDesignUtil.setDesign(temp)
          const d={
            data:{
              name: Util.randString(),
              images: [
                uploadRes[0].id,
              ],
              designData:JSON.stringify(designData),
              isDel: false,
              isUploadPng: isUploadPng,
              diyType: parseInt(diyType),
              createdBy: user.username,
              updatedBy: user.username,
            }
            
          }
          
          const designPlanRes = await design.post(d);
          const orderRes = await order.post({data: {planId: designPlanRes.data.id}});
          setIsUploading(false);
          // https://graphql.myshopify.com/cart/36607875587:1?payment=shop_pay
            setCheckoutLink(`https://diykeycap.com/cart/${orderRes.data.product.variants[0].id}:1?payment=shop_pay`);
          
          setShowModal(true);
          const _t = setTimeout(()=> {
            var animation = document.getElementById('successAnimation');
            animation.classList.remove('animated');
            void animation.parentNode.offsetWidth;
            animation.classList.add('animated');
          }, 200)
        }
      } 
      else{
        userInfo.showLoginWin()
      }

    }catch(e){
      console.log(e)
      //TODO handle the exception
    }
    
  };


  
  const handleCheckout  = ()=> {
    newWin(checkoutLink);
    setShowModal(false);
  }


  return (
    <div>
    <div
      id="permalink"
      role="button"
      aria-label={message}
      onClick={buy}
      className={styles.action}
      onMouseEnter={() => {
        setVisible(true);
      }}
      onMouseLeave={() => {
        setVisible(false);
      }}
    >
      <input
        type="text"
        readOnly
        value={getPermalink()}
        aria-label="permalink field"
        ref={node}
        style={{
          cursor: "pointer",
          position: "absolute",
          pointerEvents: "none",
          opacity: "0",
        }}
      />

        <PurchaseIcon className="w-6 h-6 min-h-0" />
        <span className="ml-1 text-sm font-medium">Place Order</span>
      
      
      {visible && (
        <div role="tooltip" className={styles.tooltip}>
          {message}
        </div>
      )}
    </div>
    
    <Modal 
           isOpen={isShowModal}
           overlayClassName=" fixed top-0 left-0 bg-black w-screen h-screen z-10 bg-opacity-40 flex justify-center items-center  "
           className="w-128 bg-black bg-opacity-90 rounded-md p-10 flex flex-col text-white items-center justify-center"
        >
          <SuccessIcon />

          <h1 className="mt-2 text-xl font-medium break-all text-center">Congratulations, the order is successful. Please check out</h1>
          
          <span className="text-primary p-2 border-solid border-primary text-sm  border px-4 rounded-md mt-6 hover:bg-primary hover:text-white cursor-pointer" onClick={handleCheckout}>Checkout now</span>
          
          {/* <button onClick={handleCloseModal}>Close Modal</button> */}
        </Modal>
    </div>
  );
}
