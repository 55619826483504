import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./Image.module.scss";
import iconUpload from "../../assets/icons/icon-upload.png";
import * as userInfo from "../../util/userinfo"
import * as settingsActions from "../../store/slices/settings";
import {  toast } from 'react-toastify';

export default function Image(props) {
  const [uploaedFile, setIploaedFile] = useState(null);

  const isUploadPng = useSelector(settingsActions.selectIsUploadPng);


  //选中图片，预览头像
  const onImgFileFn = (e) => {

      const user = userInfo.getUserInfo()
      if(!user ||  !user.username){
        userInfo.showLoginWin()
        return;
      }

      e.preventDefault()
      //获取图片文件，图片类型，图片大小
      const file = e.target.files[0]

      props.fileChangeEvent(file)

      if(!file){
        return false
      }
      const type = file.type
      const size = file.size

      const regularType = isUploadPng ?  ['image/png', 'image/jpg', 'image/jpeg'] : ['image/svg+xml'];
      //cconst regularType = 
      //图片类型限制
      if (regularType.indexOf(type.toLowerCase()) === -1) {
        toast.error(`Only supports ${isUploadPng?'PNG': 'SVG'} file!`,  {
          type: toast.TYPE.ERROR,
          theme: "dark",
          position: "top-center",
          autoClose: 2000
        })
        return false
      }
  //图片大小限制
      if (size && size > 5242880) {
        // 5 * 1024 * 1024，size的单位是字节
      //   Toast.fail('The image size should less than 5M!')
        return false
      }
  //图片文件转成base64格式
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        setImgPreviewUrl(reader.result)
        props.handler(file)

      }
  }

const setImgPreviewUrl = (file)=>{
  // uploaedFile = file;
  setIploaedFile(file);
    // document.getElementById(props.Id).src = file
}

  return ( 
    <div className={styles.uploadImage + ' relative w-40 h-40 bg-light1 box-content transition-all duration-300 cursor-pointer rounded overflow-hidden leading-none mb-4'} >
        {!uploaedFile? <div className="p-10 opacity-40 cursor-pointer">
            <img src={iconUpload} />
        </div> :
        <img src={uploaedFile} alt=""  id={props.Id} className=" w-full h-full object-cover" top="0" left="0" zoom="1" />}
        <input width="500px"
             className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
             onChange={(e) => onImgFileFn(e)}
             type='file'
             name='fileSelect'
             accept={isUploadPng ?  'image/png': 'image/svg+xml'}
         /> 
        {/* <img   id={props.Id} alt="" /> */}
    </div>
  );
}
