/**
 * 网络请求配置
 */
 import axios from "axios";
 import  * as userinfo  from  "../util/userinfo";


 import {  toast } from 'react-toastify';
 axios.defaults.timeout = 100000;
//  axios.defaults.baseURL = "http://localhost:1337/api/";
 axios.defaults.baseURL = "/api/";
 var toastLoading;
 /**
  * http request 拦截器
  */
 axios.interceptors.request.use(
   (config) => {
    toastLoading = toast.loading("Please wait...", {
          position: "top-center",
          theme: "dark",
    })
    
    // config.data = JSON.stringify(config.data);
    config.headers = {
      "Content-Type": "application/json",
      'Authorization':  userinfo.getUserToken() ? 'Bearer ' +  userinfo.getUserToken() : undefined,
      ...config.headers
    };
    return config;
   },
   (error) => {
     toast.dismiss(toastLoading);
     return Promise.reject(error);
   }
 );
 
 /**
  * http response 拦截器
  */
 axios.interceptors.response.use(
   (response) => {
      toast.dismiss(toastLoading);
     
     return response;
   },
   (error) => {
        toast.dismiss(toastLoading);
        if (error.response) {
            if (error.response.status == 401) {
              userinfo.userLogout();
            }
            // 请求已发出，但服务器响应的状态码不在 2xx 范围内
            toast.error(error.response.data.error.message,  {
                type: toast.TYPE.ERROR,
                theme: "dark",
                position: "top-center",
                autoClose: 2000
            })
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log( "Error", error.message );
        }
        return Promise.reject(error);
    }
 );
 
 /**
  * 封装get方法
  * @param url  请求url
  * @param params  请求参数
  * @returns {Promise}
  */
 export function get(url, params = {}) {
   return new Promise((resolve, reject) => {
     axios.get(url, {
         params: params,
       }).then((response) => {
         landing(url, params, response.data);
         resolve(response.data);
       })
       .catch((error) => {
         reject(error);
       });
   });
 }
 
 /**
  * 封装post请求
  * @param url
  * @param data
  * @returns {Promise}
  */
 
 export function post(url, data, config) {
   return new Promise((resolve, reject) => {
     axios.post(url, data, config).then(
       (response) => {
         //关闭进度条
         resolve(response.data);
       },
       (err) => {
        console.log(err)
         reject(err);
       }
     );
   });
 }
 
 /**
  * 封装patch请求
  * @param url
  * @param data
  * @returns {Promise}
  */
 export function patch(url, data = {}) {
   return new Promise((resolve, reject) => {
     axios.patch(url, data).then(
       (response) => {
         resolve(response.data);
       },
       (err) => {
         msag(err);
         reject(err);
       }
     );
   });
 }
 
 /**
  * 封装put请求
  * @param url
  * @param data
  * @returns {Promise}
  */
 
 export function put(url, data = {}) {
   return new Promise((resolve, reject) => {
     axios.put(url, data).then(
       (response) => {
         resolve(response.data);
       },
       (err) => {
         msag(err);
         reject(err);
       }
     );
   });
 }
 
 //统一接口处理，返回数据
 export default function (fecth, url, param) {
   let _data = "";
   return new Promise((resolve, reject) => {
     switch (fecth) {
       case "get":
         console.log("begin a get request,and url:", url);
         get(url, param)
           .then(function (response) {
             resolve(response);
           })
           .catch(function (error) {
             console.log("get request GET failed.", error);
             reject(error);
           });
         break;
       case "post":
         post(url, param)
           .then(function (response) {
             resolve(response);
           })
           .catch(function (error) {
             console.log("get request POST failed.", error);
             reject(error);
           });
         break;
       default:
         break;
     }
   });
 }
 
 //失败提示
 function msag(err) {
   if (err && err.response) {
     switch (err.response.status) {
       case 400:
         alert(err.response.data.error.details);
         break;
       case 401:
         alert("未授权，请登录");
         break;
 
       case 403:
         alert("拒绝访问");
         break;
 
       case 404:
         alert("请求地址出错");
         break;
 
       case 408:
         alert("请求超时");
         break;
 
       case 500:
         alert("服务器内部错误");
         break;
 
       case 501:
         alert("服务未实现");
         break;
 
       case 502:
         alert("网关错误");
         break;
 
       case 503:
         alert("服务不可用");
         break;
 
       case 504:
         alert("网关超时");
         break;
 
       case 505:
         alert("HTTP版本不受支持");
         break;
       default:
     }
   }
 }
 
 /**
  * 查看返回的数据
  * @param url
  * @param params
  * @param data
  */
 function landing(url, params, data) {
   if (data.code === -1) {
   }
 }
 
 