import * as THREE from "three";
import { TextureLoader } from "three/src/loaders/TextureLoader.js";

export default class ThreeUtil {
  //get place with texture applied
  static getTexturedPlane(tex) {
    let loader = new TextureLoader();
    let texture = loader.load(tex);
    let material = new THREE.MeshBasicMaterial({
      color: "#ffffff",
      map: texture,
      transparent: true,
    });
    let plane = new THREE.Mesh(new THREE.PlaneGeometry(130, 50), material);
    plane.material.side = THREE.DoubleSide;
    return plane;
  }
  //merge geometries of meshes
  static mergeMeshes(meshes) {
    let material = meshes[0].material;
    let combined = new THREE.Geometry();
    for (var i = 0; i < meshes.length; i++) {
      meshes[i].updateMatrix();
      combined.merge(meshes[i].geometry, meshes[i].matrix);
    }
    let mesh = new THREE.Mesh(combined, material);
    mesh.receiveShadow = meshes[i].receiveShadow;
    mesh.castShadow = meshes[i].castShadow;
    return mesh;
  }
  //create a box mesh with a geometry and material
  static createBox(w, h, l, x, y, z, color) {
    var material = new THREE.MeshLambertMaterial({
      color: color || "#666666",
      fog: false,
    });
    var geom = new THREE.BoxGeometry(w || 10, h || 10, l || 10);
    var mesh = new THREE.Mesh(geom, material);
    mesh.position.set(x || 0, y || 0, z || 0);
    mesh.receiveShadow = false;
    mesh.castShadow = false;
    return mesh;
  }
  //creater box from object
  static createBoxOpts(options) {
    return this.createBox(
      options.w,
      options.h,
      options.l,
      options.x,
      options.y,
      options.z,
      options.color
    );
  }

  static async getSceneScreenshot(renderer) {
    let data =await this.getSceneScreenshotPic(renderer);
    let image = document.createElement("img");
    image.src = data;
    let w = window.open("about:blank");
    w.document.write(image.outerHTML);
    w.document.close();
  }
  
  static  getSceneScreenshotPic(renderer){
    return new Promise(async (resolve) => {
      const _img = await this.clearImageEdgeBlank(renderer.domElement.toDataURL(), 10);
      resolve(_img);
    })
  }
  /**
 * 清楚图片周围空白区域
 * @param {string} url - 图片地址或base64
 * @param {number} [padding=0] - 内边距
 * @return {string} base64 - 裁剪后的图片字符串
 */
static clearImageEdgeBlank(url, padding = 0) {
  return new Promise((resolve, reject) => {
    // create canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // create image
    const image = new Image();
    image.onload = draw;
    image.src = url;
    image.crossOrigin = "Anonymous";

    function draw() {
      canvas.width = image.width;
      canvas.height = image.height;
      
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      console.log(imageData);
      const { data, width, height } = imageData;

      // 裁剪需要的起点和终点,初始值为画布左上和右下点互换设置成极限值。
      let startX = width,
        startY = height,
        endX = 0,
        endY = 0;

      /*
      col为列，row为行，两层循环构造每一个网格，
      便利所有网格的像素，如果有色彩则设置裁剪的起点和终点
      */
      for (let col = 0; col < width; col++) {
        for (let row = 0; row < height; row++) {
          // 网格索引
          const pxStartIndex = (row * width + col) * 4;

          // 网格的实际像素RGBA
          const pxData = {
            r: data[pxStartIndex],
            g: data[pxStartIndex + 1],
            b: data[pxStartIndex + 2],
            a: data[pxStartIndex + 3]
          };

          // 存在色彩：不透明
          const colorExist = pxData.a !== 0;

          /*
          如果当前像素点有色彩
          startX坐标取当前col和startX的最小值
          endX坐标取当前col和endX的最大值
          startY坐标取当前row和startY的最小值
          endY坐标取当前row和endY的最大值
          */
          if (colorExist) {
            startX = Math.min(col, startX);
            endX = Math.max(col, startX);
            startY = Math.min(row, startY);
            endY = Math.max(row, endY);
          }
        }
      }

      // 右下坐标需要扩展1px,才能完整的截取到图像
      endX += 1;
      endY += 1;

      // 加上padding
      startX -= padding;
      startY -= padding;
      endX += padding;
      endY += padding;

      // 根据计算的起点终点进行裁剪
      const cropCanvas = document.createElement("canvas");
      const cropCtx = cropCanvas.getContext("2d");
      cropCanvas.width = endX - startX;
      cropCanvas.height = endY - startY;
      cropCtx.drawImage(
        image,
        startX,
        startY,
        cropCanvas.width,
        cropCanvas.height,
        0,
        0,
        cropCanvas.width,
        cropCanvas.height
      );

      // rosolve裁剪后的图像字符串
      resolve(cropCanvas.toDataURL());
    }
  });
}}