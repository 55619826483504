import React from 'react'
import initial_settings from "../config/settings_user_default.json";
import KEYMAPS from "../config/keymaps/keymaps"
import KeyUtil from "./keyboard";
export default class userDesign{
    static design = null
    static imgId =null
    static imgList= []
    static init() {
        this.design ={}
        this.design.has =true
        this.design.layout = '100'
        this.design.legendStyle ="cherry"
        this.design.subLegends =""
        this.design.style = "CASE_1"
        this.design.material = "matte"
        this.design.primaryColor ="#ffffff"
        this.design.sceneColor ="#ffffff"
    }
  
    static getLayout() { 
        if(!this.design){
           this.init()
        }
        if(this.design.layout){
            initial_settings.case = this.design.layout
        }
        return initial_settings.case
    }
     
    static getQueryParam(name) {
      const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
      const r = window.location.search.substr(1).match(reg);
      if (r != null) return  decodeURIComponent(r[2]); return null;
    }

    static setDesign(data){
        if(!data){
           return null
        } 
        const styles =[]
        const keymap = KEYMAPS[data.layout].layers[0];
        const override = data.colorway.override
        const swatches =data.colorway.swatches
        let temp =""
        keymap.forEach(code => {
          //if(code =="KC_SPC")
           temp =""
           let group =
              KeyUtil.isMod(code) && "mods" in swatches
                ? "mods"
                : "base";
            if(override){
                temp = override[code]
            }
            let style = swatches[temp||group] || swatches["base"]
            styles.push({key:code,style:style})
        });
        this.design = data
        this.design.keyStyles = styles 
        return this.design
    }
    static getDesign(){
        return this.design
    }

    static setImgList(data){
        const temp ={
            key:data.key,
            imgId:this.imgId,
            zoom:data.zoom,
            top:data.top,
            left:data.left
        }
        this.imgList= this.imgList.filter((x)=>{return x.key!=temp.key})
        this.imgList.push(temp)
    }
    static getImgList(){
        return this.imgList
    }
    static clearImgList(){
        this.imgList = []
    }
  }
  