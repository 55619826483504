import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import threeApp from "../three/index";
import QuickActions from "../components/quickActions/QuickActions";
import Login from "../components/login/Login";
import LoginMap from "../components/login/LoginMap";

export default function Home() {

 
  const rootEl = useRef(null);
  const [showSidebar, setShowSidebar] = useState(true);
  const [showLoginWin, setShowLoginWin] = useState(false);
  useEffect(() => {
    threeApp(rootEl.current);
  }, []);

  document.addEventListener("show_loginWin", (e) => {
    setShowLoginWin(e.detail)
  });


  return (
    <>
      {showSidebar && <Sidebar />}
      <div
        id="canvas-wrapper"
        ref={rootEl}
        role="region"
        aria-label="3d scene of keyboard"
      ></div>
      <QuickActions />
      <LoginMap />
      {showLoginWin && <Login />} 
    </>
  );
}
