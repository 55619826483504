import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TestingPane from "./TestingPane";
import { useSelector, useDispatch } from "react-redux";
import OptionsPane from "./OptionsPane";
import styles from "./Sidebar.module.scss";
import ColorwayEditor from "../colorway/ColorwayEditor";
import { ReactComponent as Logo } from "../../assets/dkc.svg";
import * as settingsActions from "../../store/slices/settings";
import PictureOptions from "./PictureOptions";
import "./tabs.scss";

export default function Sidebar() {

  const dispatch = useDispatch();
  
  const [tabIndex, setTabIndex] = useState(0);

  const isUploadPng = useSelector(settingsActions.selectIsUploadPng);
  
  const diyType = useSelector(settingsActions.selectDiyType);

  const handleChangeDiyType = (e) => {
    if (e.target.dataset.val == 0) {
      window.Keys.clearPicture()
    }
    dispatch(settingsActions.diyType(e.target.dataset.val));
  }

  return (
    <div id="sidebar" className={styles.sidebar}>
      <div className={styles.intro}>
        <div className={styles.logoWrapper}>
          <h1 aria-label="Keyboard Simulator">
            <Logo />
            {/* <Name /> */}
          </h1>
        </div>
      </div>
      
      <div className="px-5 -mx-2 flex mb-1">
        <div className={'w-1/2 m-2 p-2 rounded-md cursor-pointer hover:bg-light1 transition-all duration-300'  + (diyType == 0 ? ' bg-light1 ': '')}   onClick={handleChangeDiyType} data-val={0}>
          <h3 className="font-medium  text-base leading-2" data-val={0}>Base Kit Only (No Novelties)</h3>
          <span className=" text-red-500  text-base" data-val={0}>$59.98</span>
        </div>
        <div className={'w-1/2  m-2 p-2 rounded-md cursor-pointer hover:bg-light1 transition-all duration-300'  + (diyType == 1 ? ' bg-light1 ': '')}  onClick={handleChangeDiyType} data-val={1}>
          <h3 className="font-medium  text-base leading-2" data-val={1}>Base Kit + Novelties</h3>
         
          {isUploadPng ? <span className="text-red-500 text-base">$80.98</span> :  <span className=" text-red-500 text-base" data-val={1} >$75.98</span>}
        </div>
      </div>


      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab tabIndex="0">Options</Tab>
          <Tab tabIndex="0">Editor</Tab>
          {diyType == 1 &&   <Tab tabIndex="0">IMAGE UPLOAD</Tab>}
        </TabList>
        <TabPanel>
          <OptionsPane setTab={setTabIndex} />
        </TabPanel>
        <TabPanel>
          <ColorwayEditor />
        </TabPanel> 
        {diyType == 1 &&  <TabPanel>
          <PictureOptions />
        </TabPanel>
         }
      </Tabs>
    
    </div>
  );
}
